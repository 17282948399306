.leaderboard-page {
  &__back-button {
    display: inline-flex;
  }

  &__container {
    max-width: 90%;
    margin: 0 auto;
  }

  &__title {
    font-weight: 500;
    font-size: 40px;
    line-height: 48px;
  }
}

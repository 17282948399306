body {
  background-color: #121316;
  font-size: calc(10px + 0.5vmin);
  color: white;
}

.link {
  color: #0CC6E2;
}

.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  width: fit-content;
}

@supports (-webkit-touch-callout: none) {
  .app {
    min-height: -webkit-fill-available;
  }
}

/* === Header === */
.app__bar {
  padding: 12px 25px;
  background: #151618;
  box-shadow: inset 0px -1px 0px rgba(60, 60, 60, 0.25);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.app__logo a {
  display: block;
}

.app__logo img {
  display: block;
  width: 234px;
  max-width: 100%;
}

.app__content {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
}

.app__footer {
  background-color: #151618;
  height: 72px;
}

.app__beta {
  margin-right: 1rem;
}

.positive {
  color: #AEFF03;
}

.negative {
  color: #CF2850;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-button {
  width: 0;
  height: 0;
}

::-webkit-scrollbar-thumb {
  background: #313236;
  border-radius: 100px;
}

::-webkit-scrollbar-thumb:hover {
  background: #696969;
}

::-webkit-scrollbar-thumb:active {
  background: #444;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

.detail-page {
  &__back-button {
    display: inline-flex;
  }

  &__container {
    max-width: 90%;
    margin: 0 auto;
  }

  // stonk header

  &__stonk-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title {
    font-weight: 500;
    font-size: 40px;
    line-height: 48px;
  }

  &__stonk-subheader {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.4px;
    color: rgba(255, 255, 255, 0.7);
  }

  &__graph {
    width: 100%;
    min-height: 600px;
    max-width: 100%;
  }

  &__about {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.1px;
  }

  &__content {
    max-width: 1200px;
    margin: 0 auto;
  }

  &__details {
    width: 100%;
  }

  &__metric-label {
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.1px;
    color: rgba(255, 255, 255, 0.5);
  }
}

.change-password-page {
  &__back-button {
    display: inline-flex;
  }

  &__container {
    width: fit-content;
    margin: 0 auto;
    background-color: #1B1C1F;
    border-radius: .25rem;
    justify-content: center;
  }

  &__form {
    max-width: 380px;
    text-align: center;
  }

  &__title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 44px;
  }
}

.filters {
  z-index: 3;
  background: #1b1c1f;
  border-radius: 4px;
  padding: 16px;
  margin-right: 24px;
  top: 0px;
  left: 0px;
  overflow: scroll;
}

.filters__headers {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

$dark-bg-color: #202226;
$dark-text-color: #f5f8fa;
$subtext-color: #aaaaaa;

.tweet {
  background-color: $dark-bg-color;
  color: $dark-text-color;
  border-radius: 4px;

  &__avatar {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
  }

  &__name {
    font-weight: bold;
  }

  &__rating {
    font-weight: normal;
    color: $subtext-color;
    margin-left: 0.5rem;
  }

  &__username {
    font-size: 0.8rem;
    color: $subtext-color;
  }

  &__body {
    white-space: pre-wrap;
    overflow-wrap: anywhere;
  }

  &__symbol {
    color: #0CC6E2;
  }

  &__link-highlight {
    color: #0CC6E2;
  }

  &__chart {
    width: 100%;
  }

  &__link {
    width: fit-content;
    border: 1px solid #555555;
    border-radius: 4px;
    &-meta {
      font-size: 0.8rem;
    }

    &-title {
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.4px;
      // color: rgba(255, 255, 255, 0.7);
    }

    &-description {
      color: rgba(255, 255, 255, 0.7);
    }
  }

  &__footer {
    font-size: 0.8rem;
  }

  &__date {
    color: $subtext-color;
  }

  &__sentiment {
    font-size: 0.75rem;
    background-color: #333333;
    padding: 1px 8px;
    border-radius: 10px;
    width: fit-content;

    &--bearish {
      color: #CF2850;
    }

    &--bullish {
      color: #AEFF03;
    }
  }
}

.filter-page {
  width: fit-content;
  display: flex;
  flex-direction: column;
  flex: 1;

  &__table {
    width: 100%;
  }

  &__container {
    display: flex;
  }

  &__tabs {
    margin-bottom: 16px;
    border: 0 solid;
    border-bottom-width: 1px;
    border-color: #282828;
  }

  &__sorting-options {
    padding: 8px 16px;
    background: #1b1c1f;
    border-radius: 4px;
    margin-bottom: 16px;
  }

  @media (max-width: 767px) {
    &--filter-open {
      overflow: hidden;
      width: 0;
      height: 0;
    }
  }

}

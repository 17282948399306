.stonk-table {
  &__table {
    margin-top: -8px;
    border-spacing: 0 8px;
  }

  &__row {
    border-radius: 20px;
    background-color: #1B1C1F;
  }

  &__row td:last-child,
  &__row th:last-child {
    border-radius: 0 .25rem .25rem 0;
  }

  &__row td:nth-child(1),
  &__row th:nth-child(1) {
    border-radius: .25rem 0 0 .25rem;
  }

  &__row td:nth-child(1),
  &__row th:nth-child(1) {
    position: sticky;
    left: 0;
    z-index: 1;
    border: 0px solid;
    border-right: #ffffff;
  }

  &__row td:nth-child(1) {
    background-color: #1B1C1F;
  }

  &__table-header {
    position: sticky;
    top: 0px;
    z-index: 2;
  }

  &__table-header__column {
    background-color: #121316;
    white-space: nowrap;
  }

  &__row td:nth-child(1) .stonk-table__column {
    border-right: 1px solid #333333;
  }
}

.ratio-average {
  position: relative;
  width: 100%;
  height: 20px;
  min-width: 64px;

  &__bar {
    position: relative;
    width: 100%;
    height: 2px;
    background-color: #929292;
    transform: translateY(8px);
  }

  &__min {
    position: absolute;
    left: 0;
    margin-top: 4px;
    font-size: x-small;
  }
  
  &__max {
    position: absolute;
    right: 0;
    margin-top: 4px;
    font-size: x-small;
  }
  
  &__current {
    position: absolute;
    width: 17px;
    height: 20px;
    transform: translate(-50%, -18px);
  }
}

.terms-of-service-page {
  &__back-button {
    display: inline-flex;
  }

  &__container {
    max-width: 1118px;
    margin: 0 auto;
  }

  &__title {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.1px;
  }
}

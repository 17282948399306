.loader {
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;

  &__label {
    font-weight: 500;
    font-size: 18px;
    line-height: 48px;
  }
  
}
